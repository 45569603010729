import React, { useEffect } from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, Content2Xl} from "components/misc/Layouts";
import TwoColumnWithInput from "components/hero/TwoColumnWithInput";
import TwoColSingleFeatureWithStats2 from "components/features/TwoColSingleFeatureWithStats2";
import TwoColContactUsWithIllustrationFullForm from "components/forms/TwoColContactUsWithIllustrationFullForm";
import MiniCenteredFooter from "components/footers/MiniCenteredFooter";
import ThreeColWithSideImageWithPrimaryBackground from "components/features/ThreeColWithSideImageWithPrimaryBackground";

export default () => {

  useEffect(() => {
    window.gtag("js", new Date());
    window.gtag("config", "UA-45799926-9");
  }, [])

  return (
    <AnimationRevealPage disabled>
      <Container tw="bg-gray-100 -mx-8 -mt-8 pt-8 px-8" style={{ fontFamily: 'Space Grotesk, sans-serif' }}>
        <Content2Xl>

          <TwoColumnWithInput/>
          <ThreeColWithSideImageWithPrimaryBackground/>
          <TwoColSingleFeatureWithStats2/>
          <TwoColContactUsWithIllustrationFullForm/>
          <MiniCenteredFooter/>
        </Content2Xl>
      </Container>
    </AnimationRevealPage>
  );
};