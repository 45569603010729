import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
import { css } from "styled-components/macro";
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";

import defaultCardImage from "images/shield-icon.svg";

import SupportIconImage from "images/support-icon.svg";
import ShieldIconImage from "images/shield-icon.svg";
import CustomizeIconImage from "images/customize-icon.svg";
import FastIconImage from "images/fast-icon.svg";
import ReliableIconImage from "images/reliable-icon.svg";
import SimpleIconImage from "images/simple-icon.svg";

const Container = tw.div`relative bg-primary-900 -mx-8 px-8 text-gray-100`;

const ThreeColumnContainer = styled.div`
  ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-lg mx-auto py-20 md:py-24`}
`;
const Subheading = tw(SubheadingBase)`mb-4 text-gray-100`;
const Heading = tw(SectionHeading)`w-full`;
const Description = tw(SectionDescription)`w-full text-center text-gray-300`;

const VerticalSpacer = tw.div`mt-10 w-full`;

const Column = styled.div`
  ${tw`md:w-1/2 lg:w-1/3 max-w-xs`}
`;

const Card = styled.div`
  ${tw`flex flex-col items-center sm:items-start text-center sm:text-left h-full mx-4 px-2 py-8`}
  .imageContainer {
    ${tw`bg-gray-100 text-center rounded-full p-5 flex-shrink-0`}
    img {
      ${tw`w-6 h-6`}
    }
  }

  .textContainer {
    ${tw`mt-6`}
  }

  .title {
    ${tw`tracking-wider font-bold text-xl leading-none`}
  }

  .description {
    ${tw`mt-2 font-normal text-gray-400 leading-snug`}
  }
`;

export default ({
  heading = "Financial Services",
  subheading = "",
  description = "Unleash financial potential with our all-inclusive services: tax optimization, future planning, and data security."
}) => {
  /*
   * This componets has an array of object denoting the cards defined below. Each object in the cards array can have the key (Change it according to your need, you can also add more objects to have more cards in this feature component) or you can directly pass this using the cards prop:
   *  1) imageSrc - the image shown at the top of the card
   *  2) title - the title of the card
   *  3) description - the description of the card
   *  If a key for a particular card is not provided, a default value is used
   */

  const cards = [
    { imageSrc: ShieldIconImage,
      title: "Tax Consultancy",
      description: "Maximize tax savings, plan for the future, and trust in our strict data confidentiality. Experience the difference today."
    },
    { 
      imageSrc: SupportIconImage, 
      title: "Tax Returns" ,
      description: "Effortlessly file your Income Tax Return with us, ensuring compliance and maximizing your benefits. Experience seamless tax filing today."
    },
    { 
      imageSrc: CustomizeIconImage, 
      title: "GST Returns" ,
      description: "Unlock your monthly GST input claim by filing your returns with us. Simplify the process and maximize your benefits today."
    },
    { 
      imageSrc: ReliableIconImage, 
      title: "TDS Returns",
      description: "Consult with us for TDS applicability and filing. Ensure accurate TDS compliance and seamless return filing. Trust our expertise for hassle-free TDS management."
    },
    { 
      imageSrc: FastIconImage, 
      title: "Income Tax Matters" ,
      description: "Handle income tax, GST, and other notices with confidence. Our expert team will guide you through the process, ensuring proper compliance and resolution. Relax while we handle the complexities for you."
    },
    { imageSrc: SimpleIconImage, 
      title: "Tax Planning",
      description: "Discover valuable deductions from Section 80C to 80U. Maximize your tax benefits and enhance your financial planning. Explore the possibilities today."
     }
  ];

  return (
    <Container>
      <ThreeColumnContainer>
        {subheading && <Subheading>{subheading}</Subheading>}
        <Heading>{heading}</Heading>
        {description && <Description>{description}</Description>}
        <VerticalSpacer />
        {cards.map((card, i) => (
          <Column key={i}>
            <Card>
              <span className="imageContainer">
                <img src={card.imageSrc || defaultCardImage} alt="" />
              </span>
              <span className="textContainer">
                <span className="title">{card.title || "Fully Secure"}</span>
                <p className="description">
                  {card.description || "Lorem ipsum donor amet siti ceali ut enim ad minim veniam, quis nostrud."}
                </p>
              </span>
            </Card>
          </Column>
        ))}
      </ThreeColumnContainer>
    </Container>
  );
};
